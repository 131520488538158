import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`TrackUtils`}</inlineCode>{` module contains contains utility functions related to building and parsing animation tracks.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { TrackUtils } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "interface"
    }}>{`Interface`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`TrackUtils = {
  gen,
  group,
  layer,
  layerResolvers,
  loop,
  multi
};
`}</code></pre>
    <h2 {...{
      "id": "gen"
    }}>{`gen`}</h2>
    <p>{`Parses an animation's track and configuration into a queryable `}<inlineCode parentName="p">{`Animation`}</inlineCode>{` object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const gen = <State extends object>(
  track: TrackRegion<State>[],
  defaults: State,
  config?: TrackConfig<State>
) => Animation<State>;
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`track`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/docs/api/types#trackregion"
            }}><inlineCode parentName="a">{`TrackRegion<State>[]`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The array of regions that make up the animation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaults`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation's default state.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/docs/api/types#trackconfig"
            }}><inlineCode parentName="a">{`TrackConfig<State>`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Various configuration for the computed animation.`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: `}<a parentName="li" {...{
          "href": "/docs/api/types#animation"
        }}><inlineCode parentName="a">{`Animation<State>`}</inlineCode></a></li>
    </ul>
    <p><strong parentName="p">{`This function contains React Ensemble's core animation parsing engine.`}</strong></p>
    <p><inlineCode parentName="p">{`Timeline`}</inlineCode>{` calls `}<inlineCode parentName="p">{`gen`}</inlineCode>{` internally to calculate its animation, but there may be instances where you would want to parse and query animations directly.`}</p>
    <p>{`Roughly, `}<inlineCode parentName="p">{`gen`}</inlineCode>{` calculates an animation via these steps:`}</p>
    <ol>
      <li parentName="ol">{`Apply config defaults for values not provided.`}</li>
      <li parentName="ol">{`Separate track regions by layer.`}</li>
      <li parentName="ol">{`Validate regions layer-by-layer, creating a frame state getter function for each region based on its properties.`}</li>
      <li parentName="ol">{`When groups are encountered, calculate a sub-animation for each group recursively.`}</li>
      <li parentName="ol">{`Add every region to an interval tree, including data about each state property's activity.`}</li>
      <li parentName="ol">{`Build a frame state getter for the entire interval tree involving the configured layer resolver.`}</li>
      <li parentName="ol">{`Return the frame state getter function and assorted data via an `}<inlineCode parentName="li">{`Animation`}</inlineCode>{` object.`}</li>
    </ol>
    <h3 {...{
      "id": "gen----config-defaults"
    }}>{`gen -- Config Defaults`}</h3>
    <p>{`The following default config properties will be used for every value of `}<inlineCode parentName="p">{`config`}</inlineCode>{` not specified:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`endBehavior`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"stop"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Lib.d3Ease.easeCubic`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Lib.d3Interpolate.interpolate`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resolver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TrackUtils.layerResolvers.overrideLast`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "gen---playground"
    }}>{`gen - Playground`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const [tick, setTick] = React.useState("0");
  const { getFrameState } = TrackUtils.gen(
    [
      { duration: 1000, state: { x: { to: 10 }, y: { to: 100 } } },
      { duration: 500, state: { x: { to: 50 } }, loop: { boomerang: true } }
    ],
    { x: 0, y: 0 },
    { easing: Lib.d3Ease.easeLinear, endBehavior: "continue" }
  );
  const frameState = getFrameState(Number(tick) || "0");

  return (
    <div>
      <label for="tick" style={{ marginRight: 6 }}>
        Tick
      </label>
      <input
        id="tick"
        name="tick"
        value={tick}
        onChange={e => setTick(e.target.value)}
      />
      <pre>Frame State: {JSON.stringify(frameState)}</pre>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "group"
    }}>{`group`}</h2>
    <p>{`Places one or more track regions into a single group.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const group = <State extends object>(
  regions: TrackRegionSingleOrArray<State>,
  config?: Omit<TrackRegionGroup<State>, "regions">
) => TrackRegionGroup<State>;
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`regions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TrackRegion`}</inlineCode>{` or `}<inlineCode parentName="td">{`TrackRegion[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region or regions to be grouped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Omit<TrackRegionGroup<State>, "regions">`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any region properties to be applied to the finished group, excluding `}<inlineCode parentName="td">{`regions`}</inlineCode>{`. (Optional)`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: `}<a parentName="li" {...{
          "href": "/docs/api/types#trackregiongroup"
        }}><inlineCode parentName="a">{`TrackRegionGroup<State>`}</inlineCode></a></li>
    </ul>
    <h3 {...{
      "id": "group----playground"
    }}>{`group -- Playground`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const region = TrackUtils.group(
    [{ end: 2000, state: { x: { to: 10 } } }, { duration: 1000 }],
    { loop: true }
  );
  return (
    <div>
      <pre>Generated Region: {JSON.stringify(region, null, 2)}</pre>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "layer"
    }}>{`layer`}</h2>
    <p>{`Returns one or more new regions with the given layer name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const layer = <State extends object>(
  layerName: string | number,
  regions: TrackRegionSingleOrArray<State>
) => TrackRegionSingleOrArray<State>;
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`layerName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The layer name to apply to the regions. Since `}<inlineCode parentName="td">{`TrackRegion.layer`}</inlineCode>{` must be a string, a number passed here will be cast to a string.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`regions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TrackRegion`}</inlineCode>{` or `}<inlineCode parentName="td">{`TrackRegion[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region or regions to be copied with the new layer.`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: `}<inlineCode parentName="li">{`TrackRegion`}</inlineCode>{` or `}<inlineCode parentName="li">{`TrackRegion[]`}</inlineCode>{`, depending on whether or not one or more regions were passed to the function.`}</li>
    </ul>
    <h3 {...{
      "id": "layer----playground"
    }}>{`layer -- Playground`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const regions = TrackUtils.layer(1, [
    { start: 1000, duration: 1000, state: { x: { to: 10 } } },
    { duration: 2000, state: { x: { to: 5 } }, loop: true }
  ]);
  return (
    <div>
      <pre>Generated Region(s): {JSON.stringify(regions, null, 2)}</pre>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "layerresolvers"
    }}>{`layerResolvers`}</h2>
    <p>{`Contains layer resolver functions—currently, only `}<a parentName="p" {...{
        "href": "#layerresolversoverridelast"
      }}><inlineCode parentName="a">{`overrideLast`}</inlineCode></a>{`—to be used by `}<inlineCode parentName="p">{`gen`}</inlineCode>{` to calculate an animation's frame state getter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const layerResolvers = {
  overrideLast: TrackLayerResolver
};
`}</code></pre>
    <p>{`Layer resolvers are used to calculate the composite value for a single animation state property that exists in multiple track layers at the same time.
When such a case arises, information about the layers that contain the state property are given to the animation's track layer resolver, and the output of the resolver is used as the state property's value for that frame.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/docs/guides/layers#layer-resolution"
      }}>{`Layer Resolution guide`}</a>{` or `}<a parentName="p" {...{
        "href": "/docs/api/types#tracklayerresolver"
      }}><inlineCode parentName="a">{`TrackLayerResolver`}</inlineCode>{` type definition`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "layerresolversoverridelast"
    }}>{`layerResolvers.overrideLast`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#tracklayerresolver"
        }}><inlineCode parentName="a">{`TrackLayerResolver`}</inlineCode></a></li>
    </ul>
    <p>{`Selects the value candidate with the lowest age (least amount of time since it was last updated).`}</p>
    <p>{`If multiple candidates have the same age, the one with the highest layer rank will be used.
Layer rank is calculated by alphanumerically sorting all layer names that exist in the track.`}</p>
    <h2 {...{
      "id": "loop"
    }}>{`loop`}</h2>
    <p>{`Places one or more regions into a group with the specified loop configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const loop = <State extends object>(
  regions: TrackRegionSingleOrArray<State>,
  config: boolean | number | LoopConfig
): TrackRegionGroup<State>
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`regions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TrackRegion`}</inlineCode>{` or `}<inlineCode parentName="td">{`TrackRegion[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region or regions to be placed into the looping group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode>{` or `}<inlineCode parentName="td">{`number`}</inlineCode>{` or `}<a parentName="td" {...{
              "href": "/docs/api/types#loopconfig"
            }}><inlineCode parentName="a">{`LoopConfig`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If a `}<inlineCode parentName="td">{`number`}</inlineCode>{`, the region will be configured to loop that number of times. Otherwise, the config passed here will be the group's `}<inlineCode parentName="td">{`loop`}</inlineCode>{` property. See the `}<a parentName="td" {...{
              "href": "/docs/guides/looping"
            }}>{`Looping guide`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: `}<a parentName="li" {...{
          "href": "/docs/api/types#trackregiongroup"
        }}><inlineCode parentName="a">{`TrackRegionGroup<State>`}</inlineCode></a></li>
    </ul>
    <h3 {...{
      "id": "loop----playground"
    }}>{`loop -- Playground`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const regions = TrackUtils.loop(
    [{ duration: 1000, state: { x: { to: 10 } } }, { duration: 2000 }],
    5
  );
  return (
    <div>
      <pre>Generated Region(s): {JSON.stringify(regions, null, 2)}</pre>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "multi"
    }}>{`multi`}</h2>
    <p>{`Flattens a collection of tracks into a single group's track, with the regions from each sub-track on separate layers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const multi = <State extends object>(
  tracks:
    | TrackRegionSingleOrArray<State>[]
    | Record<string, TrackRegionSingleOrArray<State>>,
  config?: Omit<TrackRegionGroup<State>, "regions">
): TrackRegionGroup<State>
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`track`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Either: An array of `}<inlineCode parentName="td">{`TrackRegion`}</inlineCode>{` and `}<inlineCode parentName="td">{`TrackRegion[]`}</inlineCode>{`, `}<em parentName="td">{`or`}</em>{` an object with each value as a `}<inlineCode parentName="td">{`TrackRegion`}</inlineCode>{` or `}<inlineCode parentName="td">{`TrackRegion[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tracks to be flattened. If a 2D array is passed, each track's new layer will be its index in the 2D array. If an object is passed, the key referring to each track will be used as the layer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Omit<TrackRegionGroup<State>, "regions">`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any region properties to be applied to the finished group, excluding `}<inlineCode parentName="td">{`regions`}</inlineCode>{`. (Optional)`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: `}<a parentName="li" {...{
          "href": "/docs/api/types#trackregiongroup"
        }}><inlineCode parentName="a">{`TrackRegionGroup<State>`}</inlineCode></a></li>
    </ul>
    <p>{`This function is useful for assembling groups with multiple concurrent layers.`}</p>
    <h3 {...{
      "id": "multi----playground"
    }}>{`multi -- Playground`}</h3>
    <p>{`If the input track is a list of lists/single regions, each layer name will be the index of the input sub-track:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const regions = TrackUtils.multi([
    [{ duration: 1000, state: { x: { to: 10 } } }, { duration: 2000 }],
    { start: 2000, state: { y: { to: 0 } } }
  ]);
  return (
    <div>
      <pre>Generated Region(s): {JSON.stringify(regions, null, 2)}</pre>
    </div>
  );
};
`}</code></pre>
    <p>{`If the input track is a object where every value is a list or single region, each layer name will be the key of that group:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const regions = TrackUtils.multi({
    foo: [{ duration: 1000, state: { x: { to: 10 } } }, { duration: 2000 }],
    bar: { start: 2000, state: { y: { to: 0 } } }
  });
  return (
    <div>
      <pre>Generated Region(s): {JSON.stringify(regions, null, 2)}</pre>
    </div>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      